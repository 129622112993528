.App-header {
  width: 100%;
  background: linear-gradient(to bottom, rgb(255, 255, 255), rgb(192, 215, 227), rgb(115, 89, 220));
  /* background: linear-gradient(to bottom, rgb(255, 255, 255), rgb(243, 228, 228), rgb(229, 63, 63)); */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}