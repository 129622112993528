@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
    p{
        @apply 
        font-space
    }
    .share--icon{
        @apply
        rounded-full
    }
    .rotateXX{
        transform:rotate(180deg);
    }
}